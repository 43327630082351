@use "src/styles/common/variables";

.component-loading {
  display: flex;
  justify-content: center;
  margin: variables.$spacing-m;

  .lds-ring {
    transform: scale(2.5);

    div {
      border-color: variables.$button-color transparent transparent transparent;
    }
  }
}
