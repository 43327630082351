@use "src/styles/common/variables";

.jobs-section {
  section {
    a {
      color: variables.$button-color;
      transition: color 0.3s;

      &:hover {
        color: variables.$success-color;
        transition: color 0.3s;
      }
    }
  }
}
