@use "src/styles/common/variables";

.lds-ring {
  width: 30px;
  height: 30px;

  :nth-child(1) {
    animation-delay: -0.45s;
  }

  :nth-child(2) {
    animation-delay: -0.3s;
  }

  :nth-child(3) {
    animation-delay: -0.15s;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: variables.$background-color transparent transparent
      transparent;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
