@use "src/styles/common/variables";

.content-animation {
  .content-animation-container {
    display: flex;
    flex-direction: column;
    margin-top: variables.$spacing-m;

    .content-animation-carousel {
      background-color: transparent;
      margin-bottom: variables.$spacing-m;
      margin-left: variables.$spacing-s;
      margin-right: variables.$spacing-s;

      .content-animation-row {
        display: flex;
      }
    }
  }

  .content-animation-title {
    height: variables.$spacing-s;
    width: 100%;
    margin-bottom: variables.$spacing-s;
  }

  .content-animation-product {
    height: 100px;
    flex: 1;

    &:not(:first-child) {
      margin-left: variables.$spacing-s;
    }

    &.--invisible-on-mobile {
      display: none;
    }
  }

  .content-animation-gradient {
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: contentAnimation;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(variables.$background-color, 0.7) 8%,
      rgba(variables.$button-color, 0.7) 38%,
      rgba(variables.$background-color, 0.7) 54%
    );
    background-size: 1000px 640px;
  }
}

@keyframes contentAnimation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@include variables.mq-for-tablet {
  .content-animation {
    .content-animation-container {
      .content-animation-carousel {
        margin-bottom: variables.$spacing-l;
        margin-left: variables.$spacing-m;
        margin-right: variables.$spacing-m;
      }
    }

    .content-animation-title {
      width: 33%;
    }

    .content-animation-product {
      height: 150px;
      flex: 1;

      &.--invisible-on-mobile {
        display: block;
      }
    }
  }
}
