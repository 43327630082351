@use "src/styles/common/variables";

.page-404 {
  text-align: center;

  h1 {
    color: variables.$primary-color;
  }

  button {
    margin-top: variables.$spacing-m;
  }
}
