@use "src/styles/common/variables";

.manage-credit-card-delete-icon,
.manage-credit-card-edit-icon {
  font-size: variables.$font-size-l;
  cursor: pointer;

  p {
    margin-bottom: 5px;
    text-align: center;
    transition: color 0.3s;

    &:hover {
      color: variables.$button-color;
      transition: color 0.3s;
    }
  }
}
