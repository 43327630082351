@use "src/styles/common/variables";

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-card {
  position: relative;
  width: 90%;
  color: variables.$primary-color;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 30px;
}

.form-input {
  width: 100%;
  background-color: variables.$background-color;
  border: none;
  margin: 2px 0;
  padding: variables.$spacing-xs;
  color: variables.$primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  font-size: variables.$font-size-m;

  &:hover::placeholder {
    color: variables.$primary-color;
  }
}

.form-button {
  background-color: variables.$button-color;

  &:hover {
    cursor: pointer;
  }
}

.rememberforgot-container {
  margin-top: variables.$spacing-xs;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: variables.$secondary-color;
}

.signup-instead-container {
  margin-top: variables.$spacing-xs;
  font-size: variables.$font-size-m;
  font-weight: 500;

  span {
    color: variables.$secondary-color;
  }

  a {
    text-decoration: none;
    color: variables.$primary-color;
  }
}

label.form-input {
  display: flex;
}

.remember-container > * {
  cursor: pointer;
}

select {
  font-size: variables.$font-size-xs;
  padding: variables.$spacing-xs;
  appearance: none;
  color: variables.$secondary-color;
  outline: none;

  option {
    color: variables.$primary-color;

    :first-child {
      color: variables.$secondary-color;
    }
  }
}

@include variables.mq-for-tablet {
  .form-card {
    width: 500px;
    color: variables.$primary-color;
    padding: 60px;
  }
}
