@use "src/styles/common/variables";

.larger-img {
  transform: scale(1.7);

  @media (max-width: 767px) {
    transform: scale(0.5); 
    // width: 100%; 
  }
}

.smaller-img {
  transform: none;

  @media (max-width: 768px) {
    transform: scale(0.3);
  }


}


.more-right {
  margin-right: 10px;
}

.compatibilities-container {
  display: flex;
  width: 100%;
  padding: variables.$spacing-s;
  border-bottom: 3px solid white;
  flex-direction: column;

  .compatibilities-presentation {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .compatibilities-presentation-content {
      margin-top: 15px;

      p {
        text-align: center;
      }
    }
  }

  .compatibilities-tools {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: variables.$spacing-xs;

    .compatibilities-tools-row {
      display: flex;
      // align-items: center;
      flex-direction: column;

      .compatibilities-tools-img {
        padding-bottom: variables.$spacing-s;
        // margin-right: 50px

        @media (max-width: 767px) {
          img.more-right {
            position: relative;
            top: 40px;
            right: 25%
          }
        }

      }
    }
  }
}

@include variables.mq-for-tablet {
  .compatibilities-container {
    flex-direction: row;
    padding: 40px 70px;

    .compatibilities-presentation {
      .compatibilities-presentation-content {
        p {
          text-align: left;
        }
      }
    }

    .compatibilities-tools {
      margin-top: 0px;

      .compatibilities-tools-row {
        display: flex;
        align-items: center;
        // flex-direction: row;
        @media (min-width: 900px) {
          flex-direction: row;
        }


        .compatibilities-tools-img {
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 15px;

          img {
            width: variables.$spacing-l;
          }

        }
      }
    }
  }
}
