// spacing

$spacing-xs: 10px;
$spacing-s: 25px;
$spacing-m: 50px;
$spacing-l: 100px;

$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 20px;
$font-size-xl: 26px;
$font-size-xxl: 35px;

// media-queries

$tablet: 768px;
$desktop: 992px;

@mixin mq-for-tablet() {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin mq-for-desktop() {
  @media (min-width: $desktop) {
    @content;
  }
}

// colors

$primary-color: white;
$secondary-color: grey;
$error-color: #bc3131;
$button-color: #00cccc;
$background-color: black;
$accordion-color: #303030;
$success-color: #97d497;
$outline-color: grey;
