@use "src/styles/common/variables";

.nomad-section-container {
  display: flex;
  justify-content: center;
  width: 100%;

  section {
    padding: 20px 25px;
    color: variables.$primary-color;
    background-color: rgba(0, 0, 0, 0.7);
    width: 85%;
    margin-bottom: variables.$spacing-l;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: variables.$spacing-m;
  }
}

.nomad-section-title {
  text-align: left;
  color: variables.$primary-color;
  margin-bottom: variables.$spacing-s;
}

@include variables.mq-for-tablet {
  .nomad-section-container {
    section {
      padding: 60px 75px;
      width: 70%;
    }
  }
}

@include variables.mq-for-desktop {
  .nomad-section-container {
    section {
      width: 50%;
    }
  }
}
