@use "src/styles/common/variables";

.carousel-container {
  display: flex;
  align-items: center;

  .carousel-container-tile-img {
    width: 100%;
  }
}

.carousel-container-category-title {
  color: variables.$primary-color;
  text-align: center;
}

.carousel-container-tile-title {
  color: variables.$primary-color;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
}

.slick-track {
  display: flex;
  align-items: center;
}

@include variables.mq-for-tablet {
  .carousel-container-category-title {
    text-align: left;
    margin-left: 45px;
  }
}

.carousel-status {
  display: none;
}

.slick-list {
  width: 100vw;
}

.slick-dots {
  bottom: 0;
  display: flex;
  justify-content: center;
}

.carousel-arrows {
  z-index: 1;
  height: 100%;
  width: variables.$spacing-m;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  background-color: transparent;
  margin-bottom: 16px;
  cursor: pointer;

  &.previous {
    margin-right: 5px;
  }

  img {
    display: block;
  }
}
