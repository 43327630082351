@use "src/styles/common/variables";

.subscribe-to-plan {
  // width: 100%;
  h2 {
    margin-bottom: variables.$spacing-xs;
  }

  .subscribe-to-plan-wrap-plan {
    margin-bottom: variables.$spacing-xs;
  }
}

// .subscriptionClass {
//   width: 100%; // Adjust width as needed
//   padding: 10px;
//   border: 1px solid grey;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px; // Adjust font size as needed

//   option {
//     padding: 10px;
//     background-color: white;
//     color: black;
//   }

//   option:selected {
//     background-color: lightgrey;
//   }

//   &:hover {
//     border-color: darkgrey;
//   }
// }


.firstSpan {
  background-color: grey;
  // color: black;
  padding: 10px;
  width: 100px;
}

.secondSpan {
  background-color: white;
  width: 200px;
  text-align: center;
  padding: 10px;

}

.thirdSpan {
  background-color: white;
  padding-right: 10px;
  padding: 10px;
  width: 150px;
}

.selectedSubscribe {
  border: 2px solid #00cccc;
}

.subscriptionOption {
  display: flex;
  flex-direction: row;
  color: black;
  // height: 50px;
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
  cursor: pointer;
}
