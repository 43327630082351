@use "src/styles/common/variables";

.credit-card-container {
  .credit-card-form-wrap {
    h2 {
      margin-bottom: variables.$spacing-xs;
    }

    .payment-card-icons {
      display: flex;
      margin-bottom: variables.$spacing-xs;

      img {
        display: block;
        width: variables.$spacing-m;
        height: 40px;
        margin-right: variables.$spacing-xs;
      }
    }

    .credit-card-form {
      display: flex;
      flex-direction: column;

      .inputs {
        margin-bottom: variables.$spacing-s;

        input {
          margin: 0;
        }
      }
    }
  }

  .security-terms {
    text-align: center;
    margin-top: 15px;
    font-size: variables.$font-size-xs;
  }
}


.sidebyside {
  display: flex;
}

.apart {
  margin-right: 10px;
}