@use "src/styles/common/variables";

.nomads-originals {
  width: 100%;
}

.info-container {
  display: flex;
  justify-content: center;

  .web-store-search-wrap {
    display: flex;
    align-items: center;
    margin: 0px 45px;
    margin-bottom: variables.$spacing-xs;
    flex-direction: column;

    #web-store-category-sort-input {
      width: inherit;
      margin-left: variables.$spacing-xs;
      text-align: center;
    }

    .web-store-search-icon {
      font-size: variables.$font-size-xl;
      color: variables.$button-color;
      margin-right: variables.$spacing-xs;
    }

    .web-store-search-input-wrap {
      .empty-input-error {
        margin: 0;
      }
    }
  }

  .info-inner-container {
    padding: variables.$spacing-xs;

    .info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: variables.$primary-color;
      padding: 15px variables.$spacing-xs 0;

      .info-scroll-arrow svg {
        display: none;
      }
    }

    .info-content {
      width: 100%;
      color: variables.$primary-color;
      padding: 0px variables.$spacing-xs;
      font-weight: 400;
      overflow: auto;
      line-height: 1.5;
      letter-spacing: 1px;
      a {
        color: variables.$primary-color;

        &:hover {
          color: variables.$secondary-color;
        }
      }
    }
  }
}

@include variables.mq-for-tablet {
  .info-content {
    padding: 0px 20px 0;
  }

  .info-header {
    padding: 15px 20px 0;
  }

  .info-container {
    .web-store-search-wrap {
      flex-direction: row;
    }
  }
}
