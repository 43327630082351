@use "src/styles/common/variables";

.presentation {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: variables.$spacing-xs;
  justify-content: space-around;

  .presentation-title {
    color: variables.$primary-color;
  }
}

@include variables.mq-for-tablet {
  .presentation {
    margin-top: variables.$spacing-m;
  }
}
