@use "src/styles/common/variables";

.managePaymentMethods-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .manage-payment-methods-title {
    margin-bottom: variables.$spacing-xs;
  }

  .manage-payment-methods-wrapper {
    margin-bottom: variables.$spacing-xs;

    .manage-payment-methods-headers {
      display: flex;
      justify-content: space-between;
      margin-bottom: variables.$spacing-xs;
    }
  }

  .manage-payment-methods-add-card {
    color: variables.$primary-color;
    background-color: variables.$button-color;
    padding: variables.$spacing-xs 15px;
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          padding-bottom: variables.$spacing-xs;
        }
      }
    }

    tr {
      td,
      th {
        padding-right: variables.$spacing-xs;

        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}
