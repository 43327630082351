@use "src/styles/common/variables";

.circle-step {
  padding: variables.$spacing-s;
  border-radius: variables.$spacing-m;
  width: variables.$spacing-s;
  position: relative;
  background-color: variables.$button-color;
  cursor: pointer;

  &.--disabled {
    background-color: variables.$secondary-color;
    cursor: default;
  }

  &.--active {
    outline: 3px solid variables.$outline-color;
  }

  .circle-step-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: variables.$font-size-l;
    color: black;
  }
}
