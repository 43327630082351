@use "src/styles/common/variables";

.payment-profile-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .payment-profile-title-wrap {
    margin-bottom: variables.$spacing-xs;

    h2 {
      margin-bottom: variables.$spacing-s;
    }
  }
}
