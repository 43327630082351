@use "src/styles/common/variables";

.faq-title {
  color: variables.$primary-color;
  font-size: variables.$font-size-xxl;
  margin: 60px 0px 30px 0px;
}

.accordion-section {
  background-color: variables.$background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close {
    color: variables.$primary-color;
    opacity: 1;
    text-shadow: 0px;
  }

  .close:hover {
    opacity: 0.75;
  }
}

.MuiAccordion-rounded {
  border-radius: 0px;
  margin-bottom: 1px;
  font-weight: 400;
  position: relative;
  width: 100%;
  border: 0;
  font-size: variables.$font-size-xl;

  p {
    font-size: variables.$font-size-m;
    padding: 0px;
  }
}

.MuiAccordionSummary-content {
  justify-content: space-between;
  margin: 0;

  .open {
    display: block;
  }

  .close {
    display: none;
  }
}

.Mui-expanded {
  .open {
    display: none;
  }
  .close {
    display: block;
  }
}

.accordion-wrapper {
  max-width: 875px;
  width: 75%;

  .accordion-inner-wrapper {
    padding: 0px;

    .faq-section-question {
      color: variables.$primary-color;
      background-color: variables.$accordion-color;
      border: 2px solid variables.$background-color;
    }
    .accordian-details {
      background-color: variables.$primary-color;
      color: variables.$background-color;
      font-weight: bold;
      border-top-color: variables.$background-color;
      border-width: 5px 0px 0px 0px;
      border-style: solid;

      .faq-section-answer {
        margin: 0;
      }
    }
  }
}
