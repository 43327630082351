@use "src/styles/common/variables";

.activate-page {
  text-align: center;
  display: flex;
  align-items: center;

  .activate-page-invalid {
    h2 {
      margin-bottom: variables.$spacing-s;
    }
  }

  .activate-page-account-activated {
    h2 {
      margin-bottom: variables.$spacing-xs;
    }

    .activate-page-fi-circle-check {
      width: variables.$spacing-l;
      height: variables.$spacing-l;
      margin-bottom: variables.$spacing-xs;
      color: variables.$success-color;
    }
  }
}
