@use "src/styles/common/variables";

.update-profile-form {
  .update-profile-form-inputs {
    margin-bottom: variables.$spacing-xs;
  }

  .update-profile-form-wrap-edit-profile-buttons {
    display: flex;
  }

  .update-profile-form-submit-button {
    margin-left: 15px;
  }
}
