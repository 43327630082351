@use "src/styles/common/variables";

.contact-section {
  section {
    p {
      margin: 0;
    }

    .contact-section-division {
      margin-bottom: variables.$spacing-xs;

      a {
        color: variables.$button-color;
        transition: color 0.3s;

        &:hover {
          color: variables.$success-color;
          transition: color 0.3s;
        }
      }
    }
  }
}
