@use "src/styles/common/variables";

.background-subscribed {
  height: 65px;
}

.selected_tile_content_wrapper {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  justify-content: space-between;
  margin-bottom: variables.$spacing-xs;
  position: relative;

  .subscribe_area {
    margin-top: 15px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .product_name {
      font-size: variables.$font-size-xxl;
      color: variables.$primary-color;
      font-weight: 700;
    }

    .product_description {
      font-size: variables.$font-size-m;
      color: variables.$secondary-color;
      margin-top: 20px;
      max-width: 500px;
      color: variables.$primary-color;
    }

    .product_or_text {
      color: variables.$primary-color;
      padding: variables.$spacing-xs;
      font-size: variables.$font-size-l;
    }
  }

  .close_btn {
    color: variables.$primary-color;
    width: variables.$spacing-xs;
    background-color: transparent;
    cursor: pointer;
    margin-left: auto;

    svg {
      padding: 5px;
      transition: 0.3s ease;
    }
  }
}

.cart_btns {
  margin-top: variables.$spacing-s;
  margin-bottom: variables.$spacing-xs;
}

.content__description {
  color: variables.$primary-color;
  margin-top: variables.$spacing-xs;
  text-align: center;
}

.slick-slide {
  transition: 0.3s ease;
  text-align: center;
  padding-right: 5px;

  &:hover {
    transform: scale(1.1);
  }
}

.selected-tile-content-sub-label {
  margin: 0;
}

.selected-tile-content-wrap-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-tile-content-info {
  font-size: variables.$font-size-l;
  padding: variables.$font-size-m;
  color: variables.$background-color;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: variables.$button-color;

  &.--subscribed {
    background-color: variables.$secondary-color;
  }
}

.selected-tile-content-checkmark-img {
  display: inline;
  width: 20px;
  margin-left: variables.$spacing-xs;
}

.selected-tile-container-wrap {
  margin: variables.$spacing-xs variables.$spacing-s;
}

.selected-tile-content-wrap-watch-preview {
  .selected-tile-content-watch-preview {
    display: flex;
    align-items: center;
  }

  .selected-tile-content-play-icon {
    margin-right: variables.$spacing-xs;
  }

  button {
    margin-top: 20px;

    p {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.watch-preview-modal-close-btn {
  position: absolute;
  right: 5px;
  top: -2px;
  cursor: pointer;
  color: variables.$primary-color;

  svg {
    width: 15px;
  }
}

@include variables.mq-for-tablet {
  .selected_tile_content_wrapper {
    flex-direction: row;

    .subscribe_area {
      margin-top: 0px;
    }
  }

  .selected-tile-container-wrap {
    margin: variables.$spacing-xs variables.$spacing-m;
  }
}
