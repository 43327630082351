@use "src/styles/common/variables";

.footer-container-inner {
  width: 100%;
}

.footer-container-inner-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  .footer-parent-link-container {
    width: 100%;
  }
}

.footer-links-outer-container {
  width: 100%;
}

.bottom-border {
  border-bottom: 3px solid white;
}

.footer-container {
  padding: 55px 0;
  display: flex;
  justify-content: center;
  background-color: variables.$background-color;
  width: 100%;

  .footer-parent-icon-container {
    display: flex;
    justify-content: center;
    text-align: center;

    @include variables.mq-for-tablet {
      flex-direction: row;
    }

    .footer-single-icon-container {
      margin: 0 variables.$spacing-xs;
      padding-bottom: 35px;

      svg {
        color: variables.$secondary-color;
        font-size: variables.$font-size-xxl;
        transition: fill 0.3s;

        &:hover {
          fill: variables.$button-color;
          transition: fill 0.3s;
        }
      }
    }
  }

  .footer-container-links-company-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    max-width: 875px;
  }

  .footer-parent-link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;

    .footer-single-link-container {
      width: 25%;
      margin-bottom: 5px;

      .footer-link {
        color: variables.$secondary-color;
        text-decoration: none;
        font-size: variables.$font-size-s;
        text-align: left;
        transition: color 0.3s;

        &:hover {
          text-decoration: underline;
          color: variables.$button-color;
          transition: color 0.3s;
        }
      }
    }
  }

  .footer-company-name {
    color: variables.$secondary-color;
    text-decoration: none;
    font-size: variables.$font-size-xs;
    padding-top: 20px;
    width: 100%;
  }
}

.footer-container-links {
  padding-top: 0px;
  padding-left: 0px;

  @include variables.mq-for-tablet {
    padding-left: variables.$spacing-l;
  }
}
