@use "src/styles/common/variables";

.rental {
  border: 2px solid variables.$secondary-color;

  .rental-poster {
    margin-bottom: 15px;
  }

  .rental-info {
    padding: 0px 20px;

    .rental-info-name {
      font-weight: bold;
    }

    .rental-info-rented-on {
      margin: 0;

      &.--with-space {
        margin-bottom: 15px;
      }
    }
  }
}
