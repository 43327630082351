@use "src/styles/common/variables";

.global-loading-container {
  height: 100vh;

  .global-loading-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: variables.$primary-color;
    background-image: url("/assets/homepageBackground/sky_wide.jpg");
    background-position: center;
    height: 100vh;

    .lds-ring {
      transform: scale(3);

      div {
        border-color: variables.$button-color transparent transparent
          transparent;
      }
    }
  }
}
