@use "src/styles/common/variables";

.wrap-lang-dropdown {
  position: relative;
  list-style: none;

  .lang-dropdown {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    flex-direction: column;
    display: none;
    transition: 0.3s ease;
    padding-left: 20px;

    .lang-li {
      padding-top: 5px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    .lang-dropdown {
      display: block;
    }
  }
}

.usersidebar-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background-color: variables.$background-color;
  display: flex;
  flex-flow: column;
  padding: 10px 15px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;

  .usersidebar-close-btn {
    position: absolute;
    right: variables.$spacing-xs;
    top: 5px;
    cursor: pointer;
    color: variables.$primary-color;

    svg {
      width: 15px;
    }
  }

  &.--show {
    transition: all 0.3s ease-in;
    opacity: 1;
    visibility: visible;
  }

  .usersidebar-item {
    color: variables.$primary-color;
    margin-bottom: variables.$spacing-xs;
    text-decoration: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .usersidebar-item-link {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        margin-top: -3px;
      }
    }
  }
}

.navbar-container {
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  background-color: transparent;

  .normal-menu-container {
    display: none;

    .navbar-logo-container-normal {
      padding-left: 20px;

      .pngLogo {
        display: flex;
        width: 360px;
      }
    }
  }

  .navbar-logo-container-mobile {
    display: flex;
    align-items: center;
    margin-top: variables.$spacing-xs * -1;
    z-index: 2;

    svg {
      min-width: variables.$spacing-m;
      height: variables.$spacing-m;
      padding: 0px variables.$spacing-xs;
      margin-left: variables.$spacing-xs;
      color: variables.$button-color;
      cursor: pointer;
    }

    a {
      max-width: 300px;
      margin-left: 15px;
      margin-right: 20px;
    }
  }

  .hamburger-menu-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    background-color: variables.$background-color;
    width: 100%;
    display: block;
    padding-top: 20px;
    z-index: 2;

    .navbar-link-container {
      padding: 15px;
      margin-top: variables.$spacing-m;

      .navbar-item {
        margin: 0;
      }
      
    }
  }

  .navbar-link-container {
    display: flex;
    color: variables.$primary-color;
    flex-direction: column;
    padding-right: variables.$spacing-xs;

    

    .navbar-item {
      text-decoration: none;
      padding: 2px variables.$spacing-xs 2px variables.$spacing-xs;
      font-size: variables.$font-size-m;
      color: variables.$primary-color;
      margin: 0;
      display: flex;
      align-items: center;
    
    

      svg {
        margin-right: 5px;
        margin-top: -3px;
      }

      &:hover {
        &:not(.lang-navbar-item) {
          cursor: pointer;
        }

        text-decoration: underline;
      }

    }

    .navbar-item-no-underline {
      text-decoration: none;
      padding: 2px variables.$spacing-xs 2px variables.$spacing-xs;
      font-size: variables.$font-size-m;
      color: variables.$primary-color;
      margin: 0;
      display: flex;
      align-items: center;
    
    

      svg {
        margin-right: 5px;
        margin-top: -3px;
      }

    }

    .wrap-navbar-button {
      display: inline-flex;
      // background-color: black;
      // padding: 5px 10px;

      .navbar-button {
        background-color: variables.$button-color;
        margin-bottom: auto;
        margin-top: auto;
        color: variables.$background-color;
        margin-left: variables.$spacing-xs;
        margin-right: variables.$spacing-xs;
        transition: background-color 0.3s;

        &:hover {
          background-color: variables.$success-color;
          text-decoration: none;
          transition: background-color 0.3s;
        }
      }

      .navbar-sign-in-button {
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }
  }
}

@include variables.mq-for-tablet {
  .navbar-container {
    .normal-menu-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .hamburger-menu-container,
    .navbar-logo-container-mobile {
      display: none;
    }

    .navbar-link-container {
      display: flex;
      flex-direction: row;
      color: variables.$primary-color;
      padding-right: variables.$spacing-s;

      .wrap-navbar-button {
        margin: auto;

        .navbar-sign-in-button {
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  .wrap-lang-dropdown {
    .lang-dropdown {
      position: absolute;
      padding-left: 20px;
    }
  }

  .usersidebar-container {
    width: 250px;
  }
}
