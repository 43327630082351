@use "src/styles/common/variables";

.rentals-dashboard {
  margin: 0px;
  padding: variables.$spacing-xs;
  background-color: rgba(0, 0, 0, 0.75);
  margin: variables.$spacing-xs;
  padding: variables.$spacing-s;

  h2 {
    margin-bottom: variables.$spacing-s;
    color: variables.$primary-color;
  }

  h3 {
    margin-bottom: variables.$spacing-s;
    margin-top: variables.$spacing-s;
    color: variables.$primary-color;
  }

  .rentals-dashboard-rentals {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    justify-content: center;
    row-gap: variables.$spacing-xs;
    column-gap: variables.$spacing-xs;
    margin-bottom: variables.$spacing-xs;
    color: variables.$primary-color;
  }
}

@include variables.mq-for-tablet {
  .rentals-dashboard {
    margin: variables.$spacing-m;
    padding: variables.$spacing-m;
  }
}
