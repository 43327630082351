@use "src/styles/common/variables";

.subscription-dashboard {
  .subscription-dashboard-buttons {
    .subscription-dashboard-cancel-button {
      margin-bottom: 15px;
    }
  }

  .subscription-dashboard-modal-confirm-button {
    margin-bottom: 15px;
  }

  .subscription-dashboard-months-label {
    margin: 0;
  }

  .subscription-dashboard-months {
    font-size: variables.$font-size-l;
  }

  .subscription-dashboard-status {
    margin-bottom: 15px;

    .subscription-dashboard-status-title {
      margin: 0;
    }
  }

  .subscription-dashboard-recurring-amount-label {
    margin: 0;
  }
}
