@use "src/styles/common/variables";

.credit-cart-display {
  display: flex;
  // flex-direction: column;
  // max-width: variables.$spacing-xs;

  div {
    justify-content: center;

    .credit-card-icon {
      margin-right: 15px;
      display: inline;
      width: 40px;
    }
  }

  div {
    display: flex;
    align-items: center;

    .credit-card-text {
      // text-align: center;
      // margin-top: 5px;
    }
  }
}

@include variables.mq-for-tablet {
  .credit-cart-display {
    // display: flex;
    // flex-direction: column;
    // flex-direction: row;
    max-width: none;

    div {
      .credit-card-icon {
        margin-right: 15px;
      }
    }

    div {
      .credit-card-text {
        // min-width: 400px;
        margin-top: 2px;
      }
    }
  }
}
