@use "src/styles/common/variables";

.common-button {
  height: 45px;
  display: flex;
  justify-content: center;
  background-color: variables.$button-color;
  width: 100%;
  vertical-align: middle;
  color: variables.$background-color;
  border: 0;
  transition: background-color 0.3s;

  .common-button-label {
    margin: auto;
  }

  &:hover:enabled {
    background-color: variables.$success-color;
    transition: background-color 0.3s;
  }
}
