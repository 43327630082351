@use "src/styles/common/variables";

.wrap-password-input {
  display: flex;
  align-items: center;

  .password-eye-icon {
    font-size: variables.$font-size-xxl;
    padding: variables.$spacing-xs;
    margin-right: 5px;
    cursor: pointer;

    &.show-password {
      color: variables.$primary-color;
    }

    &.hide-password {
      color: variables.$secondary-color;
    }
  }
}
