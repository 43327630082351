@use "src/styles/common/variables";

.homepage-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: variables.$primary-color;

  .homepage-big-card {
    height: 90vh;
    min-height: 300px;
    width: 70%;
    margin-top: 30px;

    h2 {
      font-size: variables.$font-size-xl;
      letter-spacing: 2px;
      text-align: center;

      @include variables.mq-for-tablet {
        font-size: variables.$font-size-xxl;
      }
    }

    p {
      font-size: variables.$font-size-s;
      line-height: 1.5;
      text-align: center;
      padding-bottom: variables.$spacing-s;

      @include variables.mq-for-tablet {
        font-size: variables.$font-size-l;
      }
    }

    h3 {
      text-align: center;
    }

    a {
      margin-bottom: 20px;
      background-color: variables.$button-color;
      color: variables.$background-color;
      width: 300px;
      transition: 0.3s ease;
      padding: variables.$spacing-xs;
      border-bottom: none;
      border-bottom: 0px;
      font-size: variables.$font-size-xl;
      text-decoration: none;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

@include variables.mq-for-tablet {
  .homepage-big-card {
    padding: 5px 68px;
    min-height: 300px;
    width: 500px;
    color: variables.$primary-color;
    padding-bottom: variables.$spacing-m;
  }
}

.home-info-wrapper {
  color: variables.$primary-color;
  background-color: variables.$background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 3px solid white;
  margin-bottom: variables.$spacing-m;

  .homepage-info-sliders-container {
    width: 100%;
  }

  .homepage-info-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: variables.$spacing-s;
    align-items: center;
    border-bottom: 3px solid white;
    justify-content: space-between;
    flex-direction: column;

    @include variables.mq-for-tablet {
      flex-direction: row;
      padding: 35px 40px;

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }
    }
  }

  .homepage-info-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;

    h1 {
      margin-bottom: 20px;
    }

    h1,
    p {
      width: 100%;
    }

    @include variables.mq-for-tablet {
      &.--right {
        h1,
        p {
          text-align: right;
        }
      }

      &.--left {
        h1,
        p {
          text-align: left;
        }
      }
    }
  }

  .homepage-info-image {
    width: 100%;

    @include variables.mq-for-tablet {
      width: 25%;
    }

    img {
      display: block;
      width: 100%;
      padding: 16px;
      max-height: 100%;
      object-fit: contain;
    }
  }

  h1 {
    color: variables.$primary-color;
    font-size: variables.$font-size-l;
    text-align: center;

    @include variables.mq-for-tablet {
      font-size: variables.$font-size-xxl;
      text-align: left;
    }
  }

  p {
    color: variables.$primary-color;
    font-size: variables.$font-size-s;
    margin: 5px;
    text-align: left;

    @include variables.mq-for-tablet {
      font-size: variables.$font-size-l;
    }
  }
}

.newletter-signin-footer {
  background-color: variables.$background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: variables.$spacing-m;

  @include variables.mq-for-tablet {
    padding-top: variables.$spacing-l;
  }

  .newsletter-footer-title {
    color: variables.$primary-color;
    font-size: variables.$font-size-s;
    text-align: center;

    @include variables.mq-for-tablet {
      font-size: variables.$font-size-l;
    }
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @include variables.mq-for-tablet {
      flex-direction: row;
    }

    .input-wrapper #newsletter-email-input {
      width: 200px;
      height: 60px;
      font-size: variables.$font-size-m;
      padding: variables.$spacing-xs variables.$spacing-xs;

      @include variables.mq-for-tablet {
        width: 400px;
      }
    }

    button {
      height: 60px;
      background-color: variables.$button-color;
      color: variables.$background-color;
      border: none;
      font-size: variables.$font-size-xl;
      width: 255px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 20px;

      @include variables.mq-for-tablet {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
}
