@use "src/styles/common/variables";

.wrap-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: variables.$spacing-m;
  z-index: 1;

  .banner-message {
    background-color: variables.$error-color;
    color: variables.$primary-color;
    width: 75%;
    padding: variables.$spacing-xs 20px;
    text-align: center;
  }
}
