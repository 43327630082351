@use "src/styles/common/variables";

.input-error-message {
  margin-bottom: variables.$spacing-xs;
  margin-left: variables.$spacing-xs;
  color: variables.$error-color;
}

.empty-input-error {
  margin-bottom: variables.$spacing-xs;
}
