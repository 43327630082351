@use "src/styles/common/variables";

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url("/assets/fonts/SF-Pro-Display-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  overflow-x: hidden;
}

h1,
h2,
h4,
h5 {
  font-weight: 400;
  margin-top: 0;
}

.main-background-image {
  background-image: url("/assets/homepageBackground/sky_wide.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  color: variables.$error-color;
  text-align: center;
}

:root {
  --swiper-theme-color: variables.$primary-color;
}

body {
  font-family: "SF-Pro-Display-Regular";
  background-color: variables.$background-color;
}

button:disabled {
  background-color: variables.$secondary-color;
  cursor: not-allowed;
}
