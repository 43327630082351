@use "src/styles/common/variables";

.user-profile-modal-shadow-cloak {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  .user-profile-modal-cancel-wrapper {
    display: flex;
    flex-direction: column;
    padding: variables.$spacing-s;
    background-color: variables.$background-color;

    .user-profile-modal-title {
      margin-bottom: 15px;

      .user-profile-modal-confirm-button-wrap {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .user-profile-modal-cancel-button {
          margin-left: 20px;
        }
      }
    }
  }

  .watch-preview-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: variables.$spacing-s;
    background-color: variables.$background-color;
    width: 95%;
    position: relative;
    z-index: 1;
  }
}

.userprofile-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .userprofile-card {
    color: variables.$primary-color;
    margin-top: variables.$spacing-l;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 60px;
    min-height: 300px;
    width: 500px;

    .user-profile-section {
      .userprofile-fullname {
        font-size: variables.$spacing-s;
      }
    }
  }
}
.subscription-info {
  .user-profile-wrap-subscription-info {
    margin-bottom: variables.$spacing-xs;

    .user-profile-status {
      display: flex;
      align-items: center;

      .user-profile-subscription-img {
        display: inline;
        width: variables.$spacing-xs;
        margin-left: variables.$spacing-xs;
      }
    }
  }
}

.userprofile-container .userprofile-card .user-profile-subscription-action {
  width: auto;
}

.userprofile-current-profile {
  .userprofile-wrap-info {
    margin-bottom: 15px;
  }

  .userprofile-edit-button {
    margin-bottom: 15px;
  }

  .userprofile-change-password-success {
    color: variables.$success-color;
  }

  .userprofile-change-password-label {
    text-align: center;
    padding-top: 15px;
  }

  .userprofile-hr {
    margin-top: 0;
  }
  .userprofile-wrap-unlink-buttons {
    display: flex;
  flex-direction: column;
  margin-top: 15px;

  .userprofile-unlink {
    flex: 1;
  }

  .userprofile-unlink-google {
    margin-top: 15px;
  }
}

}

@include variables.mq-for-tablet {

  .userprofile-current-profile {
    .userprofile-wrap-unlink-buttons {
      flex-direction: row;

      .userprofile-unlink {
        flex: 1;
      }

      .userprofile-unlink-google {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  }

  .user-profile-modal-shadow-cloak {
    .watch-preview-content {
      width: 85%;
    }
  }
}

@include variables.mq-for-desktop {
  .user-profile-modal-shadow-cloak {
    .watch-preview-content {
      width: 70%;
    }
  }
}
