@use "src/styles/common/variables";

.react-datepicker__close-icon::after,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: variables.$secondary-color;
  color: variables.$primary-color;
  top: 6px;
  right: 4px;
}

.react-datepicker__close-icon {
  margin-right: 5px;
}
