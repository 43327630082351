@use "src/styles/common/variables";


.window {
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: rgba(8, 8, 8, 0.9);
  z-index: 100000000;
  @media screen and (min-width: 475px) and (max-width: 820px) {
    width: 70%;
  }
  
  @media screen and (max-width: 474px) {
    width: 90%;
  }

}

.window-logo {
  max-width: 50%;
  
  margin: 15px 10px;

}


.line {
  width:100%;
  height:20px;
  padding: 9px 0;
  box-sizing:border-box;
  background:white content-box;
}

.title {
  font-size: 1.8em;
  font-weight: bold;
}

.intro {

}


.switch-container {
  justify-content: space-between;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  border: 2px solid white;
  width: 100%;
  border-radius: 100px;
}

.switch-container label {
  display: block;
}

.MuiSwitch-root {
  margin-top:5px;

  @media screen and (max-width: 474px) {
    margin-top:0px;
  }
}

.title-cookies {
  font-size: 1.4em;
  margin: 6px 0px 6px 20px;

  @media screen and (max-width: 474px) {
    font-size: 1.08em;
  }
  
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #00cccc !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #00cccc !important;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.save-buttons {
  margin: 10px 15px;
  padding: 5px 15px;
  border: none;
  background-color: black;
  border-radius: 20px;
  color: white;
}