@use "src/styles/common/variables";

.signup-instead-container {
  margin-bottom: 20px;

  .signup-instead-title {
    color: variables.$secondary-color;
    display: inline;
  }

  .register-btn {
    color: variables.$button-color;
    transition: color 0.3s;

    &:hover {
      color: variables.$success-color;
      transition: color 0.3s;
    }
  }
}

.reset-password-link {
  color: variables.$button-color;
  transition: color 0.3s;

  &:hover {
    color: variables.$success-color;
    transition: color 0.3s;
  }
}
