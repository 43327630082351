.wrap-checkbox-input {
  display: flex;

  input {
    width: 20px;
  }

  .checkbox-input-label {
    margin-left: 15px;
    cursor: pointer;
  }
}
