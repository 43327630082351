@use "src/styles/common/variables";

.circle-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: variables.$spacing-s;

  .circle-steps-hr {
    background-color: variables.$secondary-color;
    width: 90%;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 0;
  }
}
